import * as React from "react"
import Layout from "../../components/layout"
import Laptops from "../../images/km/km-laptops.jpg"
import Iphones from "../../images/km/km-iphones.jpg"
import { ArrowNarrowRightIcon } from "@heroicons/react/outline"

const KaleidoscopeMe = () => {
  return (
    <Layout>
      <div className="my-24 w-full md:w-4/5 mx-auto">
        <div className="flex items-baseline h-96 flex-col justify-center">
          <h1 className="text-5xl md:text-6xl font-black"><span className="highlight px-0.5">KaleidoscopeME</span></h1>
          <p className="text-lg mt-4">Marketing Website</p>
          <div className="flex h-8 mt-4">
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              web
            </p>
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              UX Design
            </p>
          </div>
        </div>
        <div className="my-10 flex flex-col gap-10">
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">ABOUT</h4>
            <div>
              <p className="text-lg">
                A website to introduce KaleidoscopeME to the world. Before this
                KaleidoscopeME had no web presence. I created an experince to
                comunicate the brand's mission and values.
              </p>
              <div className="grid grid-cols-2 gap-4 mt-6">
                <p className="font-semibold">January 2019</p>
                <p>Wordpress, PHP</p>
                <p className="font-semibold">Website</p>
                <p>
                  <strong>Client:</strong> KaleidoscopeME
                </p>
              </div>
            </div>
          </div>
          <a
            href="https://kaleidoscopeme.org"
            target="_blank" rel="noreferrer"
            className="p-10 bg-gray-100 rounded-lg text-xl font-bold text-center flex justify-center items-center group"
          >
            Visit the Site <span className="w-6 ml-2"><ArrowNarrowRightIcon/></span>
          </a>
          <img className="col-span-2 rounded-lg" src={Laptops} alt="mockup" />
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">OBJECTIVE</h4>
            <div>
              <p className="text-lg">
                The objective of this project was to create a website that could
                comunicate KaleidoscopeME's products to both potential customers
                and investors.
              </p>
            </div>
          </div>
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">SOLUTION</h4>
            <div>
              <p className="text-lg">
                The brand that KaleidoscopeME had created was playful yet sleek
                and modern. I used a combination of white space, videos, images
                and gradients to match their vision for the brand.
              </p>
            </div>
          </div>
          <img className="col-span-2 rounded-lg" src={Iphones} alt="mockup" />
        </div>
      </div>
    </Layout>
  )
}

export default KaleidoscopeMe
